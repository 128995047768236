import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { LenguageService } from './lenguage.service';

@Injectable({
  providedIn: 'root'
})
export class DataPageLoaderService {
  private tOut = 7500;
  constructor(private http: HttpClient, private langServ: LenguageService) { }


  getDataPage(page) {
    return this.http.get(`assets/data/${page}${((this.langServ.currentLang !== 'es') ? '.' + this.langServ.currentLang : '')}.html`,
     {responseType: 'text'}).pipe(timeout(this.tOut)).toPromise();
  }
}
