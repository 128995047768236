import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HorariosLoaderService {
  private tOut = 7500;
  horarios: any = {
    dias: []
  };
  constructor(public http: HttpClient) { }

  loadHorarios() {
    return this.http.get('assets/data/horarios.json').pipe(timeout(this.tOut)).toPromise().then((data: any) => {
      this.horarios = data;
    });
  }
}
